<template>
  <div>
    <multiselect 
        v-model="selected" 
        label="value" 
        track-by="key" 
        placeholder="Typ om te zoeken" 
        open-direction="bottom" 
        :options="options" 
        :multiple="true" 
        :searchable="true" 
        :loading="isLoading" 
        :internal-search="false" 
        :clear-on-select="false" 
        :close-on-select="false" 
        :options-limit="300" 
        :limit="3" 
        :limit-text="limitText" 
        :max-height="600" 
        :show-no-results="false" 
        :hide-selected="true" 
        @search-change="asyncFind">

      <span slot="noResult">No results found</span>
    </multiselect> 
  </div>
</template>

<script>
  import Multiselect from 'vue-multiselect'
  import axios from 'axios';
  import _ from 'lodash'

  export default {
    name: 'ApiLookup',
    components: {
      Multiselect
    },
    data () {
      return {
        selected: this.value,
        options: [],
        isLoading: false,
        cancelToken: null,
      }
    },
    props: {
      source: String,
      value: Array,
      filter: Object,
      context: String
    },
    watch: {
      selected(val) {
        this.$emit('input', val);
      }
    },
    methods: {
        limitText (count) {
          return `and ${count} others`
        },
        asyncFind: _.debounce(function (query) {
          if (this.cancelToken) {
            this.cancelToken.cancel()
          }
          
          this.options = []
          if (query == '') {
            this.isLoading = false;
            return
          }

          this.cancelToken = axios.CancelToken.source()

          this.isLoading = true
          this.$http
              .post(this.source, {
                  q: query,
                  filter: this.filter,
                  context: this.context
                },{
                  cancelToken: this.cancelToken.token
              })
              .then(response => {
                  this.options = response.data
                  this.isLoading = false
              }).catch(function () {
              })
        }, 500),
        clearAll () {
          this.selected = []
        }
    },
  }
</script>

<style>

</style>
